/* Google Reviews Section with Enhanced Transitions */
.join-1-percent-reviews-section {
  max-width: 1500px !important;
  margin: 20px auto !important;
  padding: 40px 20px !important;
  background-color: #f8f8f8 !important;
  border-radius: 10px !important;
  overflow-x: hidden !important; /* Prevent horizontal scroll during animations */
}

.join-reviews-container {
  width: 100% !important;
  max-width: 1400px !important;
  margin: 0 auto !important;
  position: relative !important; /* For proper positioning of animated elements */
}

/* Carousel Layout */
.join-reviews-carousel {
  display: flex !important;
  justify-content: center !important;
  align-items: stretch !important;
  position: relative !important;
  padding: 40px 0 !important;
  gap: 20px !important;
  min-height: 450px !important;
  overflow: visible !important; /* Allow overflow for animations */
}

.join-reviews-card {
  position: relative !important;
  background-color: white !important;
  padding: 30px !important;
  border-radius: 5px !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
  font-family: 'Inter', sans-serif !important;
  margin: 0 auto !important;
  overflow: hidden !important;
}

/* Main center card */
.join-reviews-center {
  flex: 0 0 60% !important; /* Fixed width for center card */
  transform: scale(1) translateX(0) !important;
  z-index: 3 !important;
  opacity: 1 !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15) !important;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, box-shadow 0.5s ease !important;
}

.join-reviews-center:hover {
  transform: translateY(-5px) scale(1) !important;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2) !important;
}

/* Side cards (left and right) */
.join-reviews-side {
  flex: 0 0 20% !important; /* Fixed smaller width for side cards */
  transform: scale(0.9) !important;
  opacity: 0.7 !important;
  filter: grayscale(30%) !important;
  z-index: 2 !important;
  transition: all 0.5s ease-in-out !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 20px !important;
  cursor: pointer !important;
}

.join-reviews-side:hover {
  opacity: 0.85 !important;
  filter: grayscale(10%) !important;
  transform: scale(0.92) !important;
}

.join-reviews-side.side-active {
  opacity: 0.9 !important;
  filter: grayscale(0%) !important;
  transform: scale(0.95) !important;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.18) !important;
}

.join-reviews-side .join-reviews-content {
  flex: 1 !important;
}

.join-reviews-side .join-reviews-rating-text,
.join-reviews-side .join-reviews-footer {
  display: none !important;
}

/* Slide animations for center card */
.join-reviews-center.slide-next-out {
  transform: translateX(-30%) scale(0.9) !important;
  opacity: 0 !important;
}

.join-reviews-center.slide-next-in {
  transform: translateX(0) scale(1) !important;
  opacity: 1 !important;
}

.join-reviews-center.slide-prev-out {
  transform: translateX(30%) scale(0.9) !important;
  opacity: 0 !important;
}

.join-reviews-center.slide-prev-in {
  transform: translateX(0) scale(1) !important;
  opacity: 1 !important;
}

/* Mobile slide animations */
.join-reviews-mobile-only.slide-next-out {
  transform: translateX(-100%) !important;
  opacity: 0 !important;
}

.join-reviews-mobile-only.slide-next-in {
  transform: translateX(0) !important;
  opacity: 1 !important;
}

.join-reviews-mobile-only.slide-prev-out {
  transform: translateX(100%) !important;
  opacity: 0 !important;
}

.join-reviews-mobile-only.slide-prev-in {
  transform: translateX(0) !important;
  opacity: 1 !important;
}

.join-reviews-progress {
  display: flex !important;
  gap: 8px !important;
  margin-bottom: 20px !important;
  justify-content: start !important;
}

.join-reviews-progress.mobile-progress {
  margin-top: 20px !important;
  margin-bottom: 0 !important;
  justify-content: center !important;
}

.join-reviews-progress-dot {
  width: 10px !important;
  height: 10px !important;
  border-radius: 5px !important;
  background-color: #e0e0e0 !important;
  transition: background-color 0.3s ease, transform 0.3s ease, width 0.3s ease !important;
  cursor: pointer !important;
}

.join-reviews-progress-dot.join-reviews-active {
  background-color: #1d88e5 !important;
  transform: scale(1.2) !important;
  width: 20px !important; /* Elongated active dot */
}

.join-reviews-progress-dot:hover {
  background-color: #1d88e5 !important;
  opacity: 0.8 !important;
}

.join-reviews-header {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 20px !important;
  justify-content: space-between !important;
}

.join-reviews-google-logo {
  width: 32px !important;
  height: auto !important;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1)) !important;
}

.join-reviews-rating {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}

.join-reviews-stars {
  display: flex !important;
  gap: 2px !important;
}

.join-reviews-star {
  color: #FFD700 !important;
  font-size: 18px !important;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1)) !important;
}

.join-reviews-rating-text {
  color: black !important;
  font-size: 15px !important;
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
}

.join-reviews-content {
  margin-bottom: 20px !important;
  min-height: 150px !important;
}

.join-reviews-author {
  font-weight: bold !important;
  color: #333 !important;
  font-size: 18px !important;
  margin-bottom: 4px !important;
  text-align: left !important;
}

.join-reviews-date {
  color: #999 !important;
  font-size: 14px !important;
  margin-bottom: 12px !important;
  text-align: left !important;
}

.join-reviews-text {
  color: #555 !important;
  line-height: 1.6 !important;
  font-size: 16px !important;
  text-align: left !important;
}

.join-reviews-footer {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.join-reviews-read-more {
  text-align: left !important;
  font-size: 15px !important;
}

.join-reviews-read-more a {
  color: #1d88e5 !important;
  text-decoration: underline !important;
  transition: color 0.3s ease !important;
}

.join-reviews-read-more a:hover {
  color: #0d5aa7 !important;
}

.join-reviews-navigation {
  display: flex !important;
  justify-content: flex-end !important;
}

.join-reviews-nav-button {
  background: #1d88e5 !important;
  border: none !important;
  color: white !important;
  font-size: 18px !important;
  cursor: pointer !important;
  padding: 8px 15px !important;
  margin-left: 10px ;
  border-radius: 5px !important;
  transition: background-color 0.3s ease, transform 0.3s ease !important;
}

.join-reviews-nav-button:hover {
  background-color: #0d5aa7 !important;
  transform: scale(1.05) !important;
}

/* Responsive layout - Hide mobile view on desktop */
.join-reviews-card.join-reviews-mobile-only {
  display: none !important;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out !important;
}

/* Mobile Responsive Styles */
@media (max-width: 992px) {
  .join-reviews-carousel {
    flex-direction: column !important;
    align-items: center !important;
    gap: 40px !important;
  }
  
  .join-reviews-center, 
  .join-reviews-side {
    flex: none !important;
    width: 90% !important;
    max-width: 600px !important;
  }
  
  .join-reviews-side {
    display: none !important; /* Hide side cards on smaller screens */
  }
}

@media (max-width: 768px) {
  .join-1-percent-reviews-section {
    padding: 30px 15px !important;
    margin: 40px auto !important;
  }
  
  .join-reviews-carousel.join-reviews-desktop-only {
    display: none !important;
  }
  
  .join-reviews-card.join-reviews-mobile-only {
    display: block !important;
    padding: 20px !important;
    max-width: 90% !important;
    margin: 0 auto !important;
  }
  
  .join-reviews-text {
    font-size: 14px !important;
    min-height: 100px !important;
  }
  
  .join-reviews-author {
    font-size: 16px !important;
  }
  
  .join-reviews-footer {
    flex-direction: column !important;
    gap: 15px !important;
  }
  
  .join-reviews-read-more {
    width: 100% !important;
    text-align: left !important;
  }
  
  .join-reviews-navigation {
    width: 100% !important;
    justify-content: left !important;
  }
  
  .join-reviews-nav-button {
    font-size: 16px !important;
    padding: 6px 12px !important;
  }
}

.join-1-percent-reviews-section h2.styled-heading:after {
  content: "" !important;
  position: absolute !important;
  bottom: -15px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 80px !important;
  height: 4px !important;
  background-color: #9CDCF8 !important;
  border-radius: 2px !important;
}

.join-1-percent-reviews-section .styled-heading {
  font-size: 2.8rem !important;
  background: linear-gradient(90deg, #333333 70%, #7cb8d6) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-align: center !important;
  display: block !important;
  margin: 0 auto 30px !important; /* Added margin bottom */
  position: relative !important; /* For the ::after element */
}

 .join-reviews-prev 
{margin-left:0px!important;}